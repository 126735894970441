import React from "react";
import { Jumbotron as Jumbo, Container } from "react-bootstrap";
import styled from "styled-components";
import "../assets/css/Jumbotron.css";
import image1 from "../assets/Images/Hero/bbis-hero-4.jpg";
import image2 from "../assets/Images/Hero/bbis-hero-panels.jpg";
import image3 from "../assets/Images/Hero/bbis-hero-pool.jpg";
import image4 from "../assets/Images/Hero/bbis-hero-2.jpg";
import image5 from "../assets/Images/Hero/bbis-air-conditioning-min.jpg";
import image6 from "../assets/Images/Hero/bbis-bio-pellets-min.jpg";
import image7 from "../assets/Images/Hero/bbis-catering-min.jpg";
import image8 from "../assets/Images/Hero/bbis-geo-min.jpg";
import image9 from "../assets/Images/Hero/BBIS-heat-pump-diagram-min.jpg";
import image10 from "../assets/Images/Hero/bbis-laundry-min.jpg";
import image11 from "../assets/Images/Hero/BBIS-Towels-min.jpg";
import image12 from "../assets/Images/Hero/bbis-underfloor-heating-min.jpg";
import image13 from "../assets/Images/Hero/bbis-boiler.jpeg";
import image14 from "../assets/Images/Hero/bbis-pipes.jpeg";
import image15 from "../assets/Images/Hero/bbis-commercial-boiler-repair.jpeg";
import image16 from "../assets/Images/Hero/bbis-powerflushing.jpg";
import image17 from "../assets/Images/Hero/bbis-commercial-1-min.jpg";
import image18 from "../assets/Images/Hero/bbis-commercial-2-min.jpg";

const Styles = styled.div`
    .jumbotron {
        background-size: 100% 100%; 
        color: white;
        min-height: 500px;
        min-width: auto;
        text-align: left;
        margin-bottom: 0px;
        && {
            background-color: transparent
        }
    }
    .btn-light {
        color: #1C75BC;
        border-color: #1C75BC;
    }
    .jumbo-list {
        font-size: 1.5rem;
        text-shadow: 0 0 40px rgba(0,0,0,.8);
        margin-top: 70px;
    }
    .img1{
        background: url('/Images/Hero/bbis-hero-4.jpg');
        background-position: inherit;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img2{
        background: url(${image2});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img3{
        background: url(${image3});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img4{
        background: url(${image4});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img5{
        background: url(${image5});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img6{
        background: url(${image6});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img7{
        background: url(${image7});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img8{
        background: url(${image8});
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: relative;
    }
    .img9{
        background: url(${image9});
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: relative;
    }
    .img10{
        background: url(${image10});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img11{
        background: url(${image11});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img12{
        background: url(${image12});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img13{
        background: url(${image13});
    }
    .img14{
        background: url(${image14});
    }
    .img15{
        background: url(${image15});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img16{
        background: url(${image16});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img17{
        background: url(${image17});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img18{
        background: url(${image18});
        background-repeat: no-repeat;
        background-size: cover;
    }
    /* MOBILE */
    @media only screen and (max-width: 800px) {
    .jumbotron {
        background-size: cover;
        min-height: 250px; 
        color: white;
        text-align: left;
        margin: 0px;
    }
    .jumbo-list {
        font-size: 1.4rem;
        text-shadow: 0 0 40px rgba(0,0,0,.8);
    }
    .img1{
        background: url(${image1});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img2{
        background: url(${image2});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img3{
        background: url(${image3});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img4{
        background: url(${image4});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img5{
        background: url(${image5});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img6{
        background: url(${image6});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img7{
        background: url(${image7});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img8{
        background: url(${image8});
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: relative;
    }
    .img9{
        background: url(${image9});
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: relative;
    }
    .img10{
        background: url(${image10});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img11{
        background: url(${image11});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img12{
        background: url(${image12});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img13{
        background: url(${image13});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img14{
        background: url(${image14});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img15{
        background: url(${image15});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .img16{
        background: url(${image16});
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 100%;
        height: auto;
    }
    .img17{
        background: url(${image17});
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 100%;
        height: auto;
    }
    .img18{
        background: url(${image18});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
`;

export const Jumbotron = ({ children, imgClassName }) => (
  <Jumbo fluid className={`jumbo pt-1 ${imgClassName ? imgClassName : ''}`} >
    <div className="overlay"></div>
    <Container>{children}</Container>
  </Jumbo>
);
