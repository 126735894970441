import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";

export default () => (
  <Layout>
    <SEO
      title="Commercial Heating"
      description="Are you in need of commercial boiler service or commercial heating services? BBIS Heating cover all commercial boiler and gas services in London, get in touch!"
      keywords="commercial boiler and gas services,commercial boiler installations london,commercial boiler installers london,commercial boiler replacement london,commercial boiler service and repair,commercial boiler engineer london,commercial boiler engineers london,commercial boiler installation london,commercial boiler maintenance london,commercial boiler repair london,commercial boiler service london,commercial gas boiler maintenance,commercial gas boiler service,commercial oil boiler servicing,commercial boilers london,commercial boiler maintenance,commercial boiler service,commercial boiler breakdown,commercial heating and boilers london,commercial heating services london,commercial plumbing and heating london,commercial plumbing and heating services,commercial heating london,commercial heating maintenance services,commercial heating system service,commercial heating contractors london,commercial heating services,commercial heating boiler,commercial heating systems,commercial heating engineers london,commercial heating,commercial pipework insulation,commercial pipework"
    />
    <Jumbotron imgClassName="img17"></Jumbotron>
    <div class="white-segment">
      <h1 class="heading-color">Commercial heating</h1>
      <p>
        Our fully qualified commercial heating engineers provide a highly
        professional commercial heating service across London and Hertfordshire.
      </p>
      <p>
        Our existing customer portfolio include schools, churches, offices,
        shops, restaurants, hotels, embassies, golf clubs, care homes,
        residential apartment blocks, factories, warehouses, health centres and
        more.
      </p>
      <p>
        We carry out all installation, servicing and repair work on many makes
        and models of boilers. Are qualified to work on all HVAC systems,
        natural gas, liquefied petroleum gas (LPG), Oil, electricity and
        renewable energy.
      </p>
      <h2 class="subheading-color">Gas pipework and flues</h2>
      <p>
        Our engineers are fully qualified to work on all aspects of pipework
        including design, installing testing and repair. We can carry out;
      </p>
      <ul>
        <li>Testing and purging</li>
        <li>Decommission / remediation</li>
        <li>Flue inspections and testing</li>
        <li>Gas line diagrams</li>
      </ul>
      <p>
        For a professional heating service contact us today on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
    </div>
  </Layout>
);
